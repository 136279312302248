/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * liberation-sans:
 *   - http://typekit.com/eulas/00000000000000003b9b2a5f
 *   - http://typekit.com/eulas/00000000000000003b9b2a60
 *   - http://typekit.com/eulas/00000000000000003b9b2a5e
 *   - http://typekit.com/eulas/00000000000000003b9b2a5d
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-05-22 18:24:41 UTC"}*/

@import url('https://p.typekit.net/p.css?s=1&k=gki1jhx&ht=tk&f=28.29.30.31&a=27595472&app=typekit&e=css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@font-face {
  font-family: 'liberation-sans';
  src: url('https://use.typekit.net/af/57baab/00000000000000003b9b2a5f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/57baab/00000000000000003b9b2a5f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/57baab/00000000000000003b9b2a5f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'liberation-sans';
  src: url('https://use.typekit.net/af/f5fedd/00000000000000003b9b2a60/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/f5fedd/00000000000000003b9b2a60/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/f5fedd/00000000000000003b9b2a60/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'liberation-sans';
  src: url('https://use.typekit.net/af/be86ef/00000000000000003b9b2a5e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/be86ef/00000000000000003b9b2a5e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/be86ef/00000000000000003b9b2a5e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'liberation-sans';
  src: url('https://use.typekit.net/af/11ee92/00000000000000003b9b2a5d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/11ee92/00000000000000003b9b2a5d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/11ee92/00000000000000003b9b2a5d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

.tk-liberation-sans {
  font-family: 'liberation-sans', sans-serif;
}
